import React, { useEffect, useRef, useState } from "react";
import "./appBlog.scss";
import Card from "./Card";
import { TableOfContents } from "./TableOfContents";
import { Share } from "./Share";
import Popup from "./BlogsForm";
import { useMediaQuery } from "@mui/material";

const SideBlog = (props) => {
  const { link, liveMediaImg, ksLiveUtm } = props;
  const [showToc, setShowToc] = useState(false);
  const [showRelatedContent, setshowRelatedContent] = useState(false);
  const [showKsLiveCard, setshowKsLiveCard] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isPopupshown, setIsPopupshown] = useState(false);
  const isMobileScreen = useMediaQuery('(max-width:800px)');

  console.log(props.isSmallScreen,'isMobileScreen');
  var firstFold = null;
  var secondFold = null;

  const openKslive = () => {
    window.open(ksLiveUtm, "_top");
  };

  try {
    firstFold = (props.toc[0]?.url).split("#")[1];
  } catch {}

  try {
    secondFold = (props.toc[1]?.url).split("#")[1];
  } catch {}

  var thirdFold = null;
  var secondFoldScrollUp = null;
  var thirdFoldLength = Math.floor(props.toc.length / 2);
  var thirdScrollUp = null;

  try {
    secondFoldScrollUp = (props.toc[thirdFoldLength - 1]?.url).split("#")[1];
    thirdScrollUp = (props.toc[props.toc.length - 1]?.url).split("#")[1];
  } catch {}

  try {
    thirdFold = (props.toc[thirdFoldLength]?.url).split("#")[1];
  } catch {}

  const observer = useRef();
  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if(!isPopupshown){
      const timer = setTimeout(() => {
  
        if ( props.isSmallScreen) {
          setIsOpen(true);
        }
  
        setIsPopupshown(true);
      }, 30000);
  
      // Cleanup the timer when the component unmounts
      return () => clearTimeout(timer);
    }
  }, [isPopupshown, props.isSmallScreen]);

  useEffect(() => {
    const topMargin = "-180px";

    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const intersecting = entry.isIntersecting;

          if (intersecting) {
            if (
              entry.target.id === "main-blog-page-header" ||
              entry.target.id === firstFold
            ) {
              setShowToc(false);
              setshowRelatedContent(false);
              setshowKsLiveCard(false);
            } else if (
              entry.target.id === secondFold ||
              entry.target.id === secondFoldScrollUp
            ) {
              setShowToc(true);
              setshowRelatedContent(true);
              setshowKsLiveCard(false);
            } else if (
              entry.target.id === thirdFold ||
              entry.target.id === thirdScrollUp
            ) {
              setShowToc(true);
              setshowRelatedContent(true);
              setshowKsLiveCard(false);
             
            } else if(entry.target.id === 'blog-tracks'){
              setShowToc(false);
              setshowRelatedContent(false);
              setshowKsLiveCard(false);
            }
          }
        });
      },
      {
        rootMargin: topMargin,
      }
    );

    observer.current.observe(document.getElementById("main-blog-page-header"));
    observer.current.observe(document.getElementById("blog-tracks"));

    if (firstFold) {
      observer.current.observe(document.getElementById(firstFold));
    }

    if (secondFold) {
      observer.current.observe(document.getElementById(secondFold));
    }

    if (secondFoldScrollUp) {
      observer.current.observe(document.getElementById(secondFoldScrollUp));
    }

    if (thirdScrollUp) {
      observer.current.observe(document.getElementById(thirdScrollUp));
    }

    if (thirdFold) {
      observer.current.observe(document.getElementById(thirdFold));
    }

    // Clean-up
    return () => observer.current.disconnect();
  }, []);

  return (
    <div className="blog-layout-container">
      {/* <Header /> */}

      <div className="blog-layout-wrapper container-max">
        {!props.hideSidebar && (
          <div className="sidebar2">
            {showToc && (
              <div className="cover mobile-none" id="sidebarHeader">
                <TableOfContents toc={props.toc} />
                <Share link={link} />
              </div>
            )}
          </div>
        )}
        <div
          className={
            "blog-content " + (props.hideSidebar ? "blog-content-center" : "")
          }
        >
          {props.children}
        </div>
        <div className="sidebar">
          
          <div className={`cover ${!showRelatedContent && 'cover-hide' }`} id="sidebar">

            <Card
              title="How to Build a Marketing CV That Recruiters Shortlist"
              course={'mlp'}
              placement={"Sidebar - D"}
            />
          </div>
          
          {/* <Sidebar3 /> */}
          {showKsLiveCard && (
            <div
              className="sideBarUpcommingEvent mobile-none"
              onClick={openKslive}
            >
              {liveMediaImg && (
                <img
                  src={`${liveMediaImg}`}
                  alt="ks-live"
                  placeholder="none"
                  className="ks-live-banner-sidebar"
                />
              )}
            </div>
          )}
        </div>
      </div>
      <Popup isOpen={isOpen} isLoading={false} handleClose={handleClose} course={props.course}
        placement={'Pop up - M'} />
      {/* <Footer /> */}
    </div>
  );
};

export default SideBlog;
